import {Controller as BaseController} from "stimulus"
import "tom-select/dist/css/tom-select"
import TomSelect from "tom-select"

export class Controller extends BaseController {
  static targets = ["select", "submitButton", "errorMessages", "scoreValue", "legend"]
  static values = {
    customAttributes: Array,
  }

  connect() {
    this.enableTS()
  }

  enableTS() {
    this.selectTargets.forEach((element) => {
      const select = new TomSelect(element, {
        maxItems: null,
      })

      if (this.customAttributesValue.includes(select.inputId)) {
        select.on("change", (values) => {
          const customValuesContainer = this.element.querySelector(
            `#custom-${select.inputId}-container`,
          )
          const showOn = customValuesContainer.attributes.show_on.nodeValue
          const customInput = this.element.querySelector(".custom-input")

          if (values.includes(showOn)) {
            customValuesContainer.classList.remove("hide")
            customInput.classList.remove("disable")
          } else {
            customValuesContainer.classList.add("hide")
            customInput.classList.add("disable")
          }
        })
      }
    })
  }

  submit(event) {
    event.preventDefault()
    const formElement = event.currentTarget
    const data = new URLSearchParams(new FormData(formElement))

    this.submitButtonTarget.disabled = true
    this.errorMessagesTarget.classList.add("d-none")
    this.errorMessagesTarget.innerHTML = ""

    let responseStatus

    fetch(formElement.action, {
      method: "POST",
      body: data,
    })
      .then((response) => {
        responseStatus = response.status
        if (responseStatus === 500)
          throw new Error("Un problème a empêché votre score d'être envoyé.")

        return response.json()
      })
      .then(({score, errors}) => {
        switch (responseStatus) {
          case 201:
            this._onSuccess(score)
            break
          case 422:
            const errorMessage = errors.join("</br>")
            this._onFailure(errorMessage)
            break
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  _onSuccess(score) {
    this.submitButtonTarget.value = "Merci"
    const selects = this.element.querySelectorAll(".select-value.multi")
    const textInput = this.element.querySelector(".custom-input")
    selects.forEach((select) => select.classList.add("disable"))
    if (textInput) textInput.classList.add("disable")

    if (score !== undefined) {
      this.scoreValueTarget.classList.remove("d-none")
      this.scoreValueTarget.innerHTML = `Votre score : ${score}`
    }

    if (this.legendTarget) {
      this.legendTarget.classList.remove("d-none")
    }

    const sliderElements = this.element.querySelectorAll(".slider-horizontal")
    sliderElements.forEach((sliderElement) => sliderElement.classList.add("disable-slider"))
  }

  _onFailure(message) {
    this.submitButtonTarget.disabled = false
    this.errorMessagesTarget.classList.remove("d-none")
    this.errorMessagesTarget.innerHTML = message
    const offsets = this.errorMessagesTarget.getBoundingClientRect()
    const top = window.pageYOffset + offsets.top - 200

    window.scroll(0, top)
  }
}
