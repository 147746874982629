import {Controller as BaseController} from "stimulus"
import "./index.scss"
import Timer from "easytimer.js"
export class Controller extends BaseController {
  static targets = ["timer", "values", "submitButton", "errorMessages", "audioPlayer"]

  connect() {
    this._init_timer()

    // document.querySelector(".media-audio-player")
  }

  _init_timer() {
    if (!this.hasTimerTarget) return

    this.timer = new Timer({
      startValues: {
        seconds: this.timerTarget.dataset.limit,
      },
      target: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      precision: "seconds",
      countdown: true,
    })

    this.valuesTarget.innerHTML = this.timer.getTimeValues().seconds

    this.timer.addEventListener("secondsUpdated", (e) => {
      this.valuesTarget.innerHTML = this.timer.getTimeValues().seconds
    })

    this.timer.addEventListener("started", (e) => {
      this.valuesTarget.innerHTML = this.timer.getTimeValues().seconds
    })

    this.timer.addEventListener("reset", (e) => {
      this.valuesTarget.innerHTML = this.timer.getTimeValues().seconds
    })

    const audioPlayer = this.audioPlayerTarget

    audioPlayer.addEventListener("pause", (e) => {
      this.timer.pause()
    })

    audioPlayer.addEventListener("play", (e) => {
      this.timer.start()
    })
  }

  restart(event) {
    event.preventDefault()
    this.timer.reset()
  }

  submit(event) {
    event.preventDefault()
    const formElement = event.currentTarget
    const data = new URLSearchParams(new FormData(formElement))

    this.submitButtonTarget.disabled = true
    this.errorMessagesTarget.classList.add("d-none")
    this.errorMessagesTarget.innerHTML = ""

    let responseStatus

    fetch(formElement.action, {
      method: "POST",
      body: data,
    })
      .then((response) => {
        responseStatus = response.status
        if (responseStatus === 500)
          throw new Error("Un problème a empêché votre score d'être envoyé.")

        return response.json()
      })
      .then((response) => {
        switch (responseStatus) {
          case 201:
            this._onSuccess()
            break
          case 422:
            const errorMessage = response.join("</br>")
            this._onFailure(errorMessage)
            break
        }
      })
      .catch((error) => {
        const errorMessage = error.message
        this._onFailure(errorMessage)
      })
  }

  _onSuccess() {
    this.submitButtonTarget.value = "Merci"
    const sliderElement = this.element.querySelector(".slider-horizontal")
    if (sliderElement) {
      sliderElement.classList.add("disable-slider")
    }
    this.element
      .querySelectorAll("input.score-value")
      .forEach((element) => (element.disabled = true))
  }

  _onFailure(message) {
    this.submitButtonTarget.disabled = false
    this.errorMessagesTarget.classList.remove("d-none")
    this.errorMessagesTarget.innerHTML = message
  }
}
